import * as React from 'react'
import './icon-hamburger.scss'

const IconHamburger = () => {
  return <div className={'icon_hamburger'}>
    <div/>
    <div/>
    <div/>
  </div>
}

export default IconHamburger
