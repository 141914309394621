import * as React from 'react'
import './footer.scss'

const Footer = ({contact, slugList}) => {
  const actualYear = (new Date()).getFullYear()
  const name = 'Hof Sonnhild'
  return <footer className={"footer"}>
    <div className={"footer__headline"}>{name}</div>
    <div className={"footer__adress"}>
      <span>{contact.vorname} {contact.nachname}</span>
      <span>{contact.adresse.strasse} {contact.adresse.hausnummer}</span>
      <span>{contact.adresse.postleitzahl} {contact.adresse.stadt} - {contact.adresse.land}</span>
    </div>
    <a className={"footer__cta"} href={"mailto:"+contact.email}>
      {contact.email}
    </a>
    <div className={"footer__navigation"}>
      {slugList.map((item, index) => {
        return <a key={index} href={item.slug}>{item.title}</a>
      })}
    </div>
    <hr/>
    <span className={"footer__copyright"}>© {actualYear} {name}</span>
  </footer>
}

export default Footer;
