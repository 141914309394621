import * as React from 'react'
import { useState } from 'react'
import './contact-form.scss'

const ContactForm = () => {
  const [email, setEmail] = useState('')
  const [message, setMessage] = useState('')
  const [mailSendState, setMailSendState] = useState(0)

  const sendMail = (e) => {
    e.preventDefault();
    if (email === '' || message === '') {
      return
    }

    fetch('https://hof-sonnhild.de/api/contact/send',
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          from: email,
          message: message,
          type: 'contact',
        })
      })
      .then((response) => {
        if (response.status === 200) {
          setMailSendState(1)
          return
        }
        setMailSendState(2)
      })
  }

  let response = null
  if (mailSendState === 1) {
    response = <div className={'contact-form__response'}>Danke, für Deine anfrage!</div>
  } else if (mailSendState === 2) {
    response = <div className={'contact-form__response'}>Da ist wohl was schief gelaufen!</div>
  }

  return <form className={'contact-form'} id="kontakt" onSubmit={sendMail}>
    {response}
    <div><input required type={'email'} placeholder={'DEINE E-MAIL'} value={email} onChange={({target: {value}}) => setEmail(value)}/>
    </div>
    <div><textarea  required placeholder={'DEIN ANLIEGEN'} value={message} onChange={({target: {value}}) => setMessage(value)}/></div>
    <div>
      <button >Anfrage senden</button>
    </div>
  </form>
}

export default ContactForm
