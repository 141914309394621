import * as React from 'react'
import Image from '../image/image'
import "./teaser.scss"
import logo from "./logo.png"

const Teaser = ({componentData}) => {
  return <div className={"teaser"}>
    <Image
      className={"teaser__background_image"}
      componentData={componentData.background_image}
    />
    <div className={"teaser__logo"}>
      <img src={logo} alt="Logo" />
    </div>
  </div>
}

export default Teaser
