import * as React from 'react'
import { Swiper, SwiperSlide } from 'swiper/react';
import Image from '../image/image'
import 'swiper/swiper.scss';
import './gallery.scss'

import { useState, useCallback } from "react";
import Gallery from 'react-photo-gallery';
import Carousel, { Modal, ModalGateway } from "react-images";

const HFGallery = ({componentData, galleryList}) => {
  const galleryId = componentData.gallery.id_name
  const gallery = galleryList.filter((gallery) => {
    return gallery.id_name === galleryId
  })[0]
  const [currentImage, setCurrentImage] = useState(0);
  const [viewerIsOpen, setViewerIsOpen] = useState(false);
  const openLightbox = useCallback((event, { photo, index }) => {
    setCurrentImage(index);
    setViewerIsOpen(true);
  }, []);

  const closeLightbox = () => {
    setCurrentImage(0);
    setViewerIsOpen(false);
  };

  if (componentData.gallery.gallery_mode === 'slider') {
    const imageSlideList = gallery.image.map((image) => <SwiperSlide>
      <Image componentData={image}/>
    </SwiperSlide>)

    return <div className={"gallery-slider"}>
      <Swiper
        spaceBetween={0}
        slidesPerView={1}
        onSlideChange={() => console.log('slide change')}
        onSwiper={(swiper) => console.log(swiper)}
      >
        {imageSlideList}
      </Swiper>
    </div>
  }

  const photos = gallery.image.map(({ image }) => {
    return {
      src: 'https://hof-sonnhild.de/api' + image.url,
      width: image.width,
      height: image.height,
    }
  })

  return <div className={"gallery-boxes"}>
    <Gallery photos={photos} onClick={openLightbox} />
    <ModalGateway>
      {viewerIsOpen ? (
        <Modal onClose={closeLightbox}>
          <Carousel
            currentIndex={currentImage}
            views={photos.map(x => ({
              ...x,
              srcset: x.srcSet,
              caption: x.title
            }))}
          />
        </Modal>
      ) : null}
    </ModalGateway>
  </div>
  }

export default HFGallery
