import * as React from 'react'
import Box from './box/box'
import Text from './text/text'
import HeadlineImageBox from './headline_image_box/headline-image-box'
import Image from './image/image'
import Teaser from './teaser/teaser'
import HFGallery from './gallery/HFGallery'
import ContactForm from './contact-form/contact-form'
import Headline from './headline/headline'

const ComponentInterpreter = ({ data, galleryList }) => {
  const renderComponent = (componentData, index) => {
    switch (componentData.strapi_component) {
      case 'web.teaser':
        return <Teaser key={index} componentData={componentData}/>
      case 'web.image':
        return <Image key={index} componentData={componentData}/>
      case 'web.headline-image-box':
        return <HeadlineImageBox key={index} componentData={componentData}/>
      case 'web.text':
        return <Text key={index} componentData={componentData}/>
      case 'web.box':
        return <Box key={index} componentData={componentData}/>
      case 'web.gallery':
        return <HFGallery key={index} componentData={componentData} galleryList={galleryList}/>
      case 'web.contact-form':
        return <ContactForm key={index}  componentData={componentData} />
      case 'web.headline':
        return <Headline key={index} componentData={componentData} />
      default:
        return <div><i>component not defined</i></div>
    }
  }

  return data.content.map(renderComponent)
}

export default ComponentInterpreter
