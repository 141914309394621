import * as React from 'react'
import "./headline-image-box.scss"

const HeadlineImageBox = ({componentData: { headline, image, headline_position }}) => {
  if (image) {
    return <div className={"headline-image-box"} id={headline.toLowerCase()}>
      <span className={`headline-image-box__label --${headline_position}`}>{headline}</span>
      <div className={"headline-image-box__image"}>
        <img src={"https://hof-sonnhild.de/api" + image.url} alt={image.alternativeText}/>
      </div>
    </div>
  }

  return <div className={"headline-image-box"}>
    <span className={`headline-image-box__label--${headline_position}`}>{headline}</span>
  </div>
}

export default HeadlineImageBox
