import * as React from 'react'
import './navigation.scss'
import { useState } from 'react'
import IconHamburger from '../icon/icon-hamburger'
import Logo from '../../images/logo.png'

const Navigation = ({ slugList }) => {
  const [isShowNavItemList, setIsShowNavItemList] = useState(false)
  const navigationListClass = isShowNavItemList ? 'navigation__item-list' : 'navigation__item-list--hide'

  const toggleShowNavigation = () => {
    setIsShowNavItemList(!isShowNavItemList)
  }

  const navigationItemList = slugList.map(
    ({ slug, title }, index) => <li key={index}>
      <a href={slug}>{title}</a>
    </li>
  )

  return <nav className={'navigation'}>
    <section className={'navigation__logo'}>
      <a href={'/'} title={'Startseite'}><img className={'navigation__logo__image'} width={'82px'} height={'48px'} src={Logo} alt={"Hof Sonnhild Logo"}/></a>
    </section>
    <section className={'navigation__icon-bar'} role={'button'}  tabIndex="0" onClick={toggleShowNavigation} onKeyPress={toggleShowNavigation}>
      <IconHamburger/>
    </section>
    <section className={navigationListClass}>
      <ul>
        {navigationItemList}
      </ul>
    </section>
  </nav>
}

export default Navigation
