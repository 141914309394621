import * as React from 'react'
import * as Markdown from 'react-remarkable'
import "./text.scss"

const Text = ({componentData}) => {
  return <div className={"text"}>
    <Markdown source={componentData.text}/>
  </div>
}

export default Text;
