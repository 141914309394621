import * as React from 'react'

const Image = ({componentData: { image }, className}) => {
  const url = image.formats ? image.formats.medium ? image.formats.medium.url : image.url : image.url
  const imgSrc = "https://hof-sonnhild.de/api" + url

  return <img className={className} src={imgSrc} alt={image.alternativeText}/>
}

export default Image
