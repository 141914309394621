import * as React from 'react'
import Layout from '../components/layout/layout'
import Navigation from '../components/navigation/navigation'
import Footer from '../components/footer/footer'
import '../styles/base.scss'
import ComponentInterpreter from '../components/strapi_components/component-interpreter'
import {Helmet} from "react-helmet";

const Page = ({ pageContext: { data, slugList, contact, galleryList } }) => {
  const metaData = [
    {
      name: 'description',
      content: data.meta_description || 'Hof Sonnhild',
    }
  ]
  return <Layout>
    <Helmet
        title={data.title}
        titleTemplate={`%s · Hof Sonnhild`}
        meta={metaData}
        htmlAttributes={{
          lang: 'de',
        }}
    />
    <Navigation slugList={[
      { slug: '/#austattung', title: 'Austattung' },
      { slug: '/#preise', title: 'Preise' },
      { slug: '/galerie', title: 'Bildergalerie' },
      { slug: '/#kontakt', title: 'Kontakt' },
    ]}/>
    <ComponentInterpreter data={data} galleryList={galleryList}/>
    <Footer contact={contact} slugList={slugList}/>
  </Layout>
}

export default Page
