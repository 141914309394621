import * as React from 'react'
import * as Markdown from 'react-remarkable'
import "./box.scss"

const Box = ({
  componentData: {
    text_left,
    text_right,
    white_background = false,
  },
}) => {
  const boxClassnameList = [
    'box',
    white_background === true ? '--white' : ''
  ].join(' ')

  return <section className={boxClassnameList}>
    <div className={'box_section'}><Markdown source={text_left}/></div>
    <div className={'box_section'}><Markdown source={text_right}/></div>
  </section>
}

export default Box
